import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {MenubarModule} from "primeng/menubar";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MenuModule} from "primeng/menu";
import {SharedModule} from "primeng/api";



@NgModule({
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
    imports: [
        CommonModule,
        MenubarModule,
        BreadcrumbModule,
        MenuModule,
        SharedModule
    ]
})
export class HeaderModule { }
