import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from "../environments/environment";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {setContext} from "@apollo/client/link/context";
import {firstValueFrom} from 'rxjs';
import {extractFiles} from 'extract-files';

function createApollo(httpLink: HttpLink, authService: AngularFireAuth) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext(async (operation, context) => {
    console.log('[CoreModule] Setting user', authService.user);

    if (authService.user == null) {
      return {};
    } else {
      const token = await firstValueFrom(authService.authState).then((user: any) => {
        return user?.getIdToken();
      });

      if (!environment.production) {
        console.log('[CoreModule] Setting GQL JWT Token', token);
      }

      if (!token) {
        return {};
      }

      return {
        headers: {
          Authorization: `JWT ${token}`
        }
      };
    }
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({
    uri: environment.api.graphql,
    extractFiles
  })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AngularFireAuth],
    },
  ],
})
export class GraphQLModule {
}
