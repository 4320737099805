import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FlexModule} from "@angular/flex-layout";



@NgModule({
  declarations: [
    AlertDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ClipboardModule,
    MatSnackBarModule,
    FlexModule
  ],
  exports: [
    AlertDialogComponent,
    MatDialogModule,
    ClipboardModule,
    FlexModule
  ]
})
export class CoreModule { }
