import {Component, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {AuthService} from "../../core/services/auth.service";
import {BreadcrumbService} from "../../core/services/breadcrumb.service";
import {Observable, of} from "rxjs";
import {Router} from "@angular/router";
import firebase from "firebase/compat";
import User = firebase.User;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mainMenuItems: MenuItem[] = [];
  profileItems: MenuItem[] = [];
  breadcrumbs$: Observable<MenuItem[]>;
  user$: Observable<User | null>;

  constructor(private auth: AuthService, private breadcrumbService: BreadcrumbService, private router: Router) {
    this.breadcrumbs$ = this.breadcrumbService.valueChanges();
    this.user$ = auth.user$();
  }

  ngOnInit(): void {
    this.profileItems = [{
      label: 'Sign Out',
      icon: 'pi pi-sign-out',
      command: () => {
        this.signOut();
      }
    }];

    this.mainMenuItems = [
      {
        label: '2023 SAJCCC',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/'],
        visible: true,
      },
    ];
  }

  signOut() {
    this.auth.signOut().then(() => {
      return this.router.navigate(['']).then().catch();
    }).then().catch();
  }

  signIn() {
    this.router.navigate(['login']).then().catch();
  }
}
