export const environment = {
  apmService: 'sajccc-web',
  firebase: {
    projectId: 'sajccc-820db',
    appId: '1:719734673188:web:ef949ab1dfce8737bfedb1',
    databaseURL: 'https://sajccc-820db.firebaseio.com',
    storageBucket: 'sajccc-820db.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBGnhz-TGmvV9PiH7HV7F1lmAQ4acELfFA',
    authDomain: 'sajccc-820db.firebaseapp.com',
    messagingSenderId: '719734673188',
  },
  production: true,
  api: {
    graphql: 'https://api.sajccc.co.za/graphql'
  },
  wildcardClosed: true,
  qualifiersClosed: true,
  knockoutClosed: false
};
