<p-menubar [model]="mainMenuItems">
  <ng-template pTemplate="start">
    <img src="assets/images/logo.png" height="40" class="mr-2">
  </ng-template>
  <ng-template pTemplate="end">
    <div class="p-toolbar-group-right mr-2">
      <a *ngIf="user$ | async as user" (click)="profileMenu.toggle($event)" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span *ngIf="user.displayName">&nbsp;Welcome, {{user.displayName}}</span>
        <span *ngIf="!user.displayName">&nbsp;Profile</span>
      </a>
      <a *ngIf="!(user$ | async)" (click)="signIn()" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>&nbsp;Sign In</span>
      </a>
      <p-menu #profileMenu [popup]="true" [model]="profileItems"></p-menu>
    </div>
  </ng-template>
</p-menubar>


<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
<!--  {{breadcrumbs | json}}-->
  <p-breadcrumb [model]="breadcrumbs"></p-breadcrumb>
</ng-container>
