import packageJson from '../../package.json';

import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {ScullyLibModule} from '@scullyio/ng-lib';
import {AppRoutingModule} from './app-routing.module';
import {FlexLayoutModule} from "@angular/flex-layout";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {GraphQLModule} from './graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {CoreModule} from "./core/core.module";
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getPerformance, providePerformance} from '@angular/fire/performance';
import {getRemoteConfig, provideRemoteConfig} from '@angular/fire/remote-config';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {AngularFireModule} from '@angular/fire/compat';
import {HeaderModule} from "./modules/header/header.module";
import {ApmErrorHandler, ApmModule, ApmService} from "@elastic/apm-rum-angular";
import {ApolloModule} from "apollo-angular";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ApmModule,
    BrowserModule,
    ScullyLibModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
    GraphQLModule,
    HttpClientModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    providePerformance(() => getPerformance()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideStorage(() => getStorage()),
    HeaderModule,
  ],
  providers: [
    ApmService,
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    ScreenTrackingService, UserTrackingService,
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(service: ApmService) {
    // Agent API is exposed through this apm instance
    const apm = service.init({
      serviceName: environment.apmService,
      environment: environment.production ? 'Production' : 'Development',
      serviceVersion: packageJson.version,
      serverUrl: 'https://apm.s1.checkboard.co.za'
    })

    // apm.setUserContext({
    //   'username': 'foo',
    //   'id': 'bar'
    // })
  }
}
