import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** Date custom scalar type */
  SimpleDateScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



export type Accommodation = {
  __typename?: 'Accommodation';
  _id: Scalars['ID'];
  /** Name of Accommodation venue */
  name: Scalars['String'];
  /** Units available under this accommodation option */
  units: Array<AccommodationUnit>;
};

export type AccommodationUnit = {
  __typename?: 'AccommodationUnit';
  availableStock?: Maybe<Scalars['Int']>;
  checkIn: Scalars['SimpleDateScalar'];
  checkOut: Scalars['SimpleDateScalar'];
  /** Bullets points to be displayed */
  infoList?: Maybe<Array<Scalars['String']>>;
  key: Scalars['ID'];
  maxPax: Scalars['Int'];
  minPax: Scalars['Int'];
  name: Scalars['String'];
  pax: Scalars['Int'];
  paxRate: Scalars['Float'];
  reservedStock?: Maybe<Scalars['Int']>;
  stock?: Maybe<Scalars['Int']>;
};

export type Address = {
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  province: Province;
  suburb: Scalars['String'];
};

export type CreateAccommodationInput = {
  /** Name of Accommodation venue */
  name: Scalars['String'];
  /** Units available under this accommodation option */
  units: Array<CreateAccommodationUnit>;
};

export type CreateAccommodationUnit = {
  checkIn: Scalars['SimpleDateScalar'];
  checkOut: Scalars['SimpleDateScalar'];
  /** Bullets points to be displayed */
  infoList?: Maybe<Array<Scalars['String']>>;
  key: Scalars['ID'];
  maxPax: Scalars['Int'];
  minPax: Scalars['Int'];
  name: Scalars['String'];
  pax: Scalars['Int'];
  paxRate: Scalars['Float'];
  reservedStock?: Maybe<Scalars['Int']>;
  stock: Scalars['Int'];
};

export type CreateBookingData = {
  accommodationId: Scalars['ID'];
  unitKey: Scalars['String'];
};

export type CreateBookingInput = {
  bookings: Array<CreateBookingData>;
};

export type CreateBookingPreferenceInput = {
  camping?: Maybe<Scalars['Boolean']>;
  pax?: Maybe<Scalars['Int']>;
  sharing?: Maybe<Scalars['Boolean']>;
  shuttle?: Maybe<Scalars['Boolean']>;
};

export type CreateEntryInput = {
  bookingPreferences?: Maybe<CreateBookingPreferenceInput>;
  /** Event to participate in */
  event: Scalars['String'];
  /** Registrant of participants */
  participants: Array<CreateParticipantInput>;
  /** Payment Details for Entry */
  payment: PaymentDetailsInput;
  /** Registrant of participants */
  registrant: CreateRegistrantInput;
};

export type CreateParticipantInput = {
  birthDate: Scalars['DateTime'];
  chessComUsername?: Maybe<Scalars['String']>;
  chessTitle?: Maybe<Scalars['String']>;
  chessaId: Scalars['String'];
  deliveryAddress?: Maybe<Address>;
  diet?: Maybe<Scalars['String']>;
  fideId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  gender: Scalars['String'];
  idDocument?: Maybe<Scalars['Upload']>;
  idNumber: Scalars['String'];
  initials: Scalars['String'];
  lastName: Scalars['String'];
  medical?: Maybe<MedicalAidInput>;
  middleName?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  race?: Maybe<Scalars['String']>;
  /** Region of participant */
  region: Regions;
  school?: Maybe<Scalars['String']>;
  /** Section where participant is playing */
  section?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  venueAddress?: Maybe<Address>;
};

export type CreateRegistrantInput = {
  acceptDeclaration: Scalars['Boolean'];
  canHostHub?: Maybe<Scalars['Boolean']>;
  contactEmail: Scalars['String'];
  contactPhone: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  relation: Scalars['String'];
  title: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<Scalars['String']>;
  username: Scalars['String'];
};


export type Entry = {
  __typename?: 'Entry';
  data: EntryData;
  /** Id of entry doc */
  id: Scalars['String'];
};

export type EntryData = {
  __typename?: 'EntryData';
  event: Scalars['String'];
  participants: Array<Participant>;
};

export type Invoice = {
  __typename?: 'Invoice';
  context: InvoiceContext;
  created: Scalars['DateTime'];
  entryId?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  invoiceNo: Scalars['Int'];
  reference: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type InvoiceContext = {
  __typename?: 'InvoiceContext';
  cols: Array<Scalars['String']>;
  dueDate: Scalars['String'];
  invoiceDate: Scalars['String'];
  reference: Scalars['String'];
  rows: Array<InvoiceContextRow>;
  totalDue: Scalars['String'];
};

export type InvoiceContextRow = {
  __typename?: 'InvoiceContextRow';
  cols: Array<Scalars['String']>;
};

export type InvoicesPaginated = {
  __typename?: 'InvoicesPaginated';
  /** List of invoices */
  data: Array<Invoice>;
  page: Paginate;
};

export type MedicalAidInput = {
  emergencyPhone: Scalars['String'];
  mainMemberName: Scalars['String'];
  memberNumber: Scalars['String'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccommodation: Scalars['Boolean'];
  createBooking: Scalars['Boolean'];
  createUser: User;
  enterParticipants: Entry;
  removeUser: User;
  updateUser: User;
  updateUsernames: Entry;
};


export type MutationCreateAccommodationArgs = {
  createAccommodationInput: CreateAccommodationInput;
};


export type MutationCreateBookingArgs = {
  createBookingInput: CreateBookingInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationEnterParticipantsArgs = {
  enterParticipants: CreateEntryInput;
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUsernamesArgs = {
  updateUsernames: UpdateUsernamesInput;
};

export type Paginate = {
  __typename?: 'Paginate';
  /** Offset of last page */
  lastPage: Scalars['Int'];
  /** Current pagination limit */
  limit: Scalars['Int'];
  /** Current pagination Offset */
  offset: Scalars['Int'];
  /** Total items in collection */
  total: Scalars['Int'];
};

export type Participant = {
  __typename?: 'Participant';
  birthDate?: Maybe<Scalars['SimpleDateScalar']>;
  chessComUsername?: Maybe<Scalars['String']>;
  chessaId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type ParticipantUsernameInput = {
  index: Scalars['Int'];
  username: Scalars['String'];
};

export type PaymentDetailsInput = {
  amount?: Maybe<Scalars['Float']>;
  method: Scalars['String'];
  proofOfPayment?: Maybe<Array<Scalars['Upload']>>;
};

export enum Province {
  EasternCape = 'EASTERN_CAPE',
  FreeState = 'FREE_STATE',
  Gauteng = 'GAUTENG',
  KwazuluNatal = 'KWAZULU_NATAL',
  Limpopo = 'LIMPOPO',
  Mpumalanga = 'MPUMALANGA',
  NorthernCape = 'NORTHERN_CAPE',
  NorthWest = 'NORTH_WEST',
  WesternCape = 'WESTERN_CAPE'
}

export type Query = {
  __typename?: 'Query';
  accommodation: Accommodation;
  accommodations: Array<Accommodation>;
  entries: Array<Participant>;
  invoices: Array<Invoice>;
  invoicesPaginated: InvoicesPaginated;
  me: User;
  participant: Entry;
  participants: Array<Entry>;
  user: User;
  users: Array<User>;
};


export type QueryAccommodationArgs = {
  name: Scalars['String'];
};


export type QueryEntriesArgs = {
  eventName: Scalars['String'];
};


export type QueryInvoicesArgs = {
  eventName: Scalars['String'];
};


export type QueryInvoicesPaginatedArgs = {
  eventName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryParticipantsArgs = {
  eventName: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};

export enum Regions {
  Eal = 'EAL',
  Ean = 'EAN',
  Ebc = 'EBC',
  Ech = 'ECH',
  Ejg = 'EJG',
  Enm = 'ENM',
  Eor = 'EOR',
  Esb = 'ESB',
  Ffd = 'FFD',
  Flw = 'FLW',
  Fmb = 'FMB',
  Ftm = 'FTM',
  Fxt = 'FXT',
  Ge = 'GE',
  Gjm = 'GJM',
  Gsv = 'GSV',
  Gtp = 'GTP',
  Gwr = 'GWR',
  Kan = 'KAN',
  Ked = 'KED',
  Kik = 'KIK',
  Ksi = 'KSI',
  Kud = 'KUD',
  Kug = 'KUG',
  Kuh = 'KUH',
  Kul = 'KUL',
  Kum = 'KUM',
  Kup = 'KUP',
  Kur = 'KUR',
  Kuz = 'KUZ',
  Kvy = 'KVY',
  Lcp = 'LCP',
  Lmg = 'LMG',
  Lsg = 'LSG',
  Lvt = 'LVT',
  Lwm = 'LWM',
  Men = 'MEN',
  Mgs = 'MGS',
  Mnw = 'MNW',
  Nbr = 'NBR',
  Ncf = 'NCF',
  Ncj = 'NCJ',
  Ncn = 'NCN',
  Ncp = 'NCP',
  Nnm = 'NNM',
  Nrs = 'NRS',
  Nwp = 'NWP',
  Nzf = 'NZF',
  Wck = 'WCK',
  Wcu = 'WCU',
  Wcw = 'WCW',
  Weg = 'WEG',
  Wos = 'WOS',
  Wp = 'WP'
}


export type UpdateUserInput = {
  _id?: Maybe<Scalars['ID']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUsernamesInput = {
  /** Entry ID */
  id: Scalars['ID'];
  otp?: Maybe<Scalars['String']>;
  participants: Array<ParticipantUsernameInput>;
};


export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type BookAccommodationMutationVariables = Exact<{
  input: CreateBookingInput;
}>;


export type BookAccommodationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBooking'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & Pick<User, '_id' | 'displayName' | 'lastName' | 'email' | 'emailVerified' | 'roles'>
  ) }
);

export type AccommodationQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type AccommodationQuery = (
  { __typename?: 'Query' }
  & { accommodation: (
    { __typename?: 'Accommodation' }
    & Pick<Accommodation, '_id' | 'name'>
    & { units: Array<(
      { __typename?: 'AccommodationUnit' }
      & Pick<AccommodationUnit, 'key' | 'name' | 'infoList' | 'pax' | 'minPax' | 'maxPax' | 'paxRate' | 'reservedStock' | 'stock' | 'availableStock' | 'checkIn' | 'checkOut'>
    )> }
  ) }
);

export type EntriesQueryVariables = Exact<{
  eventName: Scalars['String'];
}>;


export type EntriesQuery = (
  { __typename?: 'Query' }
  & { entries: Array<(
    { __typename?: 'Participant' }
    & Pick<Participant, 'chessaId' | 'birthDate' | 'firstName' | 'lastName' | 'section'>
  )> }
);

export const BookAccommodationDocument = gql`
    mutation BookAccommodation($input: CreateBookingInput!) {
  createBooking(createBookingInput: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookAccommodationGQL extends Apollo.Mutation<BookAccommodationMutation, BookAccommodationMutationVariables> {
    document = BookAccommodationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query Me {
  me {
    _id
    displayName
    lastName
    lastName
    email
    emailVerified
    roles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccommodationDocument = gql`
    query Accommodation($name: String!) {
  accommodation(name: $name) {
    _id
    name
    units {
      key
      name
      infoList
      pax
      minPax
      maxPax
      paxRate
      reservedStock
      stock
      availableStock
      checkIn
      checkOut
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccommodationGQL extends Apollo.Query<AccommodationQuery, AccommodationQueryVariables> {
    document = AccommodationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EntriesDocument = gql`
    query Entries($eventName: String!) {
  entries(eventName: $eventName) {
    chessaId
    birthDate
    firstName
    lastName
    birthDate
    section
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EntriesGQL extends Apollo.Query<EntriesQuery, EntriesQueryVariables> {
    document = EntriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }