import { Injectable } from '@angular/core';
import {Observable, of, ReplaySubject, Subject} from "rxjs";
import {MenuItem} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private items$ = new ReplaySubject<MenuItem[]>(1);
  private _currentItems: MenuItem[] = [];

  constructor() {
    this.setValue([]);
  }

  get currentItems(): MenuItem[] {
    return this._currentItems;
  }

  set currentItems(value: MenuItem[]) {
    this._currentItems = value;
  }

  last(): MenuItem {
    return this._currentItems[this._currentItems.length - 1];
  }

  setValue(items: MenuItem[]) {
    this._currentItems = [{label:'Home', routerLink: ['']}, ...items];
    this.items$.next(this._currentItems);
  }

  valueChanges(): Observable<MenuItem[]> {
    return this.items$;
  }
}
