import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Apollo} from 'apollo-angular';
import {Router} from "@angular/router";
import {map, shareReplay} from "rxjs/operators";
import {MeGQL, User as AppUser} from "../../generated/graphql";
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {ApmService} from "@elastic/apm-rum-angular";
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: User | null = null;
  private readonly _user$: Observable<User | null>;

  constructor(private auth: AngularFireAuth, private apollo: Apollo, private router: Router, private _me: MeGQL, private apmService: ApmService) {
    this._user$ = this.auth.authState.pipe(shareReplay(1));
    this._user$.subscribe((user) => {
      this.apmService.apm.setUserContext({
        username: user?.providerData[0]?.email || user?.providerData[0]?.phoneNumber || undefined,
        email: user?.email || undefined,
        id: user?.uid,
      });
      this.apmService.apm.setCustomContext({
        profile: {
          emailVerified: user?.emailVerified,
          isAnonymous: user?.isAnonymous,
          provider: user?.providerId,
          name: user?.displayName,
        }
      });
      if (user) {
        console.log('User Authenticated', user);
      } else {
        console.log('Anonymous user');
      }
      this._user = user;
    })
  }

  get me(): Observable<AppUser> {
    return this._me.watch().valueChanges.pipe(
      map(result => result.data?.me)
    );
  }

  get user(): firebase.User | null {
    return this._user;
  }

  user$(): Observable<User | null> {
    return this._user$;
  }

  async login() {
    return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  async logout() {
    return this.auth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }

  async createUserWithEmailAndPassword(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password)
      .then((res) => {
        console.log('User created', res);
        return res;
      })
      .catch();
  }

  loginWithEmailAndPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.auth.signOut();
  }
}
